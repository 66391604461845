.bento {
  width: 230px;
  height: 120px;
  flex-shrink: 0;

  border-radius: 20px;
  background: #bfe8ff;
  text-align: left;
}

.bento-container {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.txt-center {
  margin-left: 1.8rem;
  margin-top: 1.5rem;
}

.metric {
  font-family: new-spirit, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 26px;
  color: #343030;
}

.metric-txt {
  font-size: 13px;
  font-weight: 500;
}

.announce {
  width: 100%;
  max-width: 754px;
  max-height: 300px;
  background-color: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  margin-top: 80px;

  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.h-announce {
  font-family: new-spirit, serif;
  font-size: 22px;
  font-weight: 500;
  color: #000;
}

.doug-font {
  font-family: "EB Garamond", serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
}

.txt-announce {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-top: 5px;
}

.announce-box {
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 450px) {
  .bento {
    width: 100px;
    height: 100px;
  }
  .bento-container {
    gap: 0.5rem;
    margin-top: 8rem;
    margin-bottom: -3rem;
  }
  .announce {
    max-height: 500px;
  }

  .metric {
    font-size: 16px;
  }

  .metric-txt {
    font-size: 12px;
  }
  .txt-center {
    margin-left: 0.6rem;
    margin-top: 1rem;
  }
}
