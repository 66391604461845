.body {
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow-y: hidden;
  background-color: #f5f5f2;
}

.box-v {
  margin-top: 5rem;
  margin-bottom: 10rem;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 20px;
  box-shadow: none;
  padding-bottom: 3rem;
  width: 26rem;
}
.box {
  margin-top: 10rem;
  margin-bottom: 10rem;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 20px;
  box-shadow: none;
  padding-bottom: 3rem;
  width: 26rem;
}

.right-box {
  margin-top: 10rem;
  margin-bottom: 10rem;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 20px;
  box-shadow: none;
  padding-bottom: 3rem;
}

.notebook {
  max-width: 18rem;
}

.doug {
  max-width: 10rem;
}

.cartoon {
  max-width: 20rem;
}

.left-side {
  width: 100%;
  overflow-y: scroll;
}

.right-side {
  width: 100%;
  background-color: #e9f98c;
}

.right-side-v {
  width: 100%;
  background-color: #eef2cf;
}
.content {
  width: 80%;
  display: inline-block;
  font-family: "Space Grotesk";
}

@media (max-width: 800px) {
  .box {
    width: 30rem;
  }
  .right-side {
    display: none;
  }
  .right-side-v {
    display: none;
  }
}

@media (max-width: 480px) {
  .box {
    width: 90%;
  }
  .content {
    width: 86%;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
