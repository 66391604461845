@font-face {
  font-family: "avenir";
  src: local("avenir"), url("../../fonts/ABeeZee.ttf") format("truetype");
}

a {
  text-decoration: none;
  color: inherit;
}

span > a {
  text-decoration: underline;
}

.socials {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    @media (max-width: 700px) {
        flex-direction: column;
      }
}

.privacyterms{
    display: flex;
    gap: 2rem;
 
    @media (max-width: 700px) {
        gap: .5rem;
      }

}

.paper {
  margin-top: 1rem;
  width: 20vw;
  padding: 20px;
  background: linear-gradient(to right, #FFDFAD,  rgb(255, 231, 222));
  border-radius: 15px;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
}
.whitelogostyle {
    width: 20rem;
    @media (max-width: 700px) {
        width: 15rem;
        margin-top: .5rem;
      }
      
}


@media (max-width: 1100px) {

  .paper{
    width: 60vw;
  }

}
@media (max-width: 480px) {
 .whitelogostyle {
  width: 15rem;
  margin-top: 2rem;
 } 
 .socials {
  flex-direction: row;
 }
}