.pagination {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.pagination button {
  border: 1px solid #ddd;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
}

.pending-members table {
  width: 100%;
  border-collapse: collapse;
}

.pending-members th,
.pending-members td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.active-members table {
  width: 100%;
  border-collapse: collapse;
}

.active-members th,
.active-members td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
