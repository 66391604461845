.bg {
  background-color: #f5f5f2;
  position: absolute;
}

.grid {
  position: relative;
  margin-top: 12rem;
  width: 100%;
  height: 50rem;
  background-size: 80px 80px;
  background-image: linear-gradient(to right, grey 1px, transparent 1px),
    linear-gradient(to bottom, grey 1px, transparent 1px);
}

.lower-grid {
  width: 100%;
  height: 60rem;
  background-size: 80px 80px;
  background-image: linear-gradient(to right, grey 1px, transparent 1px),
    linear-gradient(to bottom, grey 1px, transparent 1px);
}

.quote {
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Space Grotesk;
  font-size: 2rem;
  font-weight: 400;
  font-style: normal;
  line-height: 150.1%; /* 3.002rem */
  margin: auto;
}

.quote-bg {
  width: 90%;
  margintop: auto;
  marginbottom: auto;
}

.logo {
  position: absolute;
  top: -5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.hero {
  position: relative;
  display: flex;
  gap: 3rem;
  justiy-content: space-between;
  width: 40rem;
  height: 30rem;
  margin-left: auto;
  margin-right: auto;
  top: 5rem;
  font-family: "Space Grotesk";
}

.left-col {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.right-col {
  position: relative;
  flex: 2;
  margin-left: -4rem;
}

.header-box {
  position: relative;
  height: 19rem;
}

.human-img {
  width: 16rem;
  float: right;
}

.neon-rec {
  justify-content: center;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 18rem;
  flex-shrink: 0;
  border-radius: 2.88rem;
  border: 3.661px solid #000;
  background-color: #e9f98c;
  z-index: 10;
  font-size: 2.5rem;
}

.shadow-rec {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 100%;
  height: 18rem;
  flex-shrink: 0;
  border-radius: 2.88rem;
  border: 0.915px solid #000;
  background-color: #000;
}

.cta-buttons {
  margin-top: 1rem;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.login-button {
  border-radius: 6.36613rem;
  border: 2.546px solid #000;
  background: #f5f5f2;
  width: 10rem;
  height: 5rem;
  flex-shrink: 0;
  text-align: center;
  font-size: 1.5rem;
  font-family: "Space Grotesk";
}

.right-buttons {
  display: flex;
  flex-direction: row;
  margin-left: -3rem;
  position: absolute;
}

.start-button {
  border-radius: 6.36613rem;
  border: 2.546px solid #000;
  background: #f5f5f2;
  width: 15rem;
  height: 5rem;
  text-align: left;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-family: "Space Grotesk";
  position: absolute;
  right: 2rem;
}

.button-hovered {
  transform: translateX(1rem);
}

.arrow-bg {
  width: 5rem;
  height: 3.4rem;
  flex-shrink: 0;
  border-radius: 2rem;
  border: 2.032px solid #000;
  background: #e9f98c;
  text-align: center;
  z-index: 10;
  margin-top: 0.8rem;
  transition: transform 0.5s;
}

.arrow-bg:hover {
  transform: translateX(1rem);
}

@media (min-width: 1200px) {
  .hero {
    width: 55rem;
    gap: 0;
  }
  .right-col {
    margin-left: 0;
  }
}

@media (min-width: 700px) and (max-width: 1199px) {
  .hero {
    width: 50rem;
    gap: 0;
  }
  .right-col {
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .human-img {
    display: none;
  }
  .hero {
    display: block;
    width: 90%;
  }
  .logo {
    width: 450px;
  }
  .right-col {
    margin-left: 0;
  }
}

@media (max-width: 450px) {
  .header-box {
    margin-top: -4rem;
    margin-bottom: -2rem;
  }
  .neon-rec {
    font-size: 2rem;
  }
  .right-buttons {
    margin-left: 12rem;
    margin-top: 1rem;
  }
  .cta-buttons {
    flex-direction: column;
  }
  .logo {
    width: 350px;
  }
  .right-col {
    margin-left: 0;
  }

  .lower-grid {
    height: 100rem;
  }
}

@media (max-width: 350px) {
  .logo {
    width: 300px;
  }
  .right-col {
    margin-left: 0;
  }
}
