.member-page {
  display: flex;
  gap: 10rem;
  min-height: 100vh;
}

@media (max-width: 1000px) {
  .member-page {
    gap: 3rem;
  }
}

@media (max-width: 450px) {
  .member-page {
    gap: 3rem;
    flex-direction: column;
  }
}
