.letter {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin: 26px auto 0;
  max-width: 600px;
  min-height: 700px;
  padding: 24px;
  position: relative;
  width: 80%;
}
.letter:before,
.letter:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.letter:before {
  background: #fafafa;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}
.letter:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}

.postcard {
  margin-top: 3rem;
}

.profile-pic {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: inherit;
}

@media (max-width: 767px) {
  .letter {
    width: 96%;
    min-height: 1000px;
  }
  .postcard {
    margin-top: 0rem;
  }
}

@media (max-width: 600px) {
  .letter {
    min-height: 1000px;
  }
}

@media (max-width: 450px) {
  .letter {
    min-height: 1200px;
  }
  .postcard {
    margin-top: 0rem;
    height: 16rem;
  }
  .profile-pic {
    width: 2.5rem;
    height: 2.5rem;
  }
}
