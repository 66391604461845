.sidebar-container {
  width: 320px;
  background-color: transparent;
  min-height: 100vh;
  position: relative;
  left: 0;
}

.sidebar-container.folded {
  width: 94px;
  min-width: 94px;
  background-color: transparent;
  min-height: 100vh;
  position: relative;
  left: 0;
}

.divider {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  top: 0;
  position: absolute;
  right: 0;
}

.divider.folded {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  position: absolute;
  right: 0rem;
}

.fold-icon {
  position: absolute;
  top: 40%;
  right: -2rem;
  z-index: 10;
  width: 1rem;
  height: 1rem;
}

.fold-icon.folded {
  transform: rotate(180deg);
}

.fold-icon:hover {
  width: 1.2rem;
  height: 1.2rem;
  color: blue;
}

.community-container {
  height: 8rem;
  width: 100%;
  text-align: center;
}

.menu-option {
  margin-left: 10px;
}

.menu-option.folded {
  display: none;
}

.menu-list {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #343030;
}

.menu-list.selected {
  background-color: rgba(233, 249, 140, 0.2);
}

@media (max-width: 450px) {
  .sidebar-container {
    height: 12rem;
    width: 100%;
  }
  .divider {
    display: none;
  }
  .fold-icon {
    display: none;
  }
}
