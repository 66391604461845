body {
  margin: 0;
  padding: 0;
  font-family: "Space Grotesk", "avenir", sans-serif;
  overflow-x: hidden;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 80px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 10;
}

.cta {
  background-color: #e9f98c;
  padding: 10px;
  border-radius: 10px;
  color: #1d2202 !important;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 20px;
  font-weight: 400;
  color: white;
}

.nav-elements ul a.active {
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
}

.account-button {
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(163, 163, 163);
  padding: 3px 8px;
  display: flex;
  gap: 3px;
  right: 2rem;
  position: absolute;
}

.account-button:hover {
  border: 2px solid #e9f98c;
  border-image-outset: 3px;
}

.account-settings {
  display: none;
}

.account-settings.active {
  display: inline-block;
  position: absolute;
  top: 35px;
  right: 0;
  background-color: #35455c;
  z-index: 10;
  color: white;
  border-radius: 10px;
  width: 15rem;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.account-item {
  margin-bottom: 3px;
}

.account-item:hover {
  color: #e9f98c;
}

@media (max-width: 480px) {
  .container {
    max-width: 100vw;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 650px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .container {
    max-width: 100%;
  }
  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 50;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
